import { Carousel } from '@v2/components';
import { Article } from './components/Article/Article';

interface Props {
  title: string;
}

const articles = [
  {
    title: 'Elite Sports Tours - Travel Blog',
    href: 'https://www.elitesportstours.ca/travel-blog',
    image:
      'https://www.elitesportstours.ca/images/2020/03/26/elite-sports-tours-travel-blog__1100x414.jpg',
    date: 'Mar, 2020',
    target: '_blank',
  },
  {
    title: 'Where Do The Sports Teams Play?',
    href: 'https://www.elitesportstours.ca/where-do-the-sports-teams-play',
    image:
      'https://www.elitesportstours.ca/images/2020/02/24/where-do-the-sports-teams-play.jpg',
    date: 'Feb, 2020',
    target: '_blank',
  },
  {
    title: 'How To Get Around?',
    href: 'https://www.elitesportstours.ca/how-to-get-around',
    image:
      'https://www.elitesportstours.ca/images/2020/04/16/how-to-get-around__1100x414.jpg',
    date: 'Apr, 2020',
    target: '_blank',
  },
  {
    title: 'Where To Eat?',
    href: 'https://www.elitesportstours.ca/where-to-eat',
    image:
      'https://www.elitesportstours.ca/images/2021/07/20/where-to-eat-travel-tips__1100x414.jpg',
    target: '_blank',
  },
  {
    title: 'Things To Do',
    href: 'https://www.elitesportstours.ca/things-to-do',
    image:
      'https://www.elitesportstours.ca/images/2021/07/22/things-to-do-traveling-1__1100x414.jpg',
    target: '_blank',
  },
];
export const LatestArticles = ({ title }: Props) => {
  return (
    <div className="flex w-full max-w-7xl flex-col">
      <h1 className="mb-6 text-lg font-bold md:text-2xl">{title}</h1>

      <Carousel>
        {articles.map((article) => (
          <div
            key={article.title}
            className="flex flex-shrink-0 flex-grow-0 basis-60 flex-col"
          >
            <Article
              title={article.title}
              date={article.date}
              url={article.href}
              image={article.image}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
