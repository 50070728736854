import { LeagueEvent } from '@interfaces/APITravel';
import { EventResponse } from '@v2/types';

export interface SearchResultData {
  id: number;
  name: string;
  url: string;
  slug_url: string;
  location: string;
  date?: string;
  time?: string;
  category: {
    category: {
      id: number;
      parent_id: number;
      name: string;
      keywords: string;
      has_home_team: boolean;
      slug: string;
    };
  } | null;
  venue?: {
    name: string;
    location: string;
  };
  occurs_at_local?: string;
  slug: string;
  _type: string;
  _score: number;
  events: number;
  listOfEvents?: {
    events: LeagueEvent[];
    total_entries: number;
  };
}

interface LocationData {
  PlaceId: string;
  Text: string;
  numberOfEvents: number;
}

export interface SearchResultsProps {
  sectionTitle: string;
  sectionItems: SearchResultData[] | LocationData[] | EventResponse[];
}

export const formatSearchResultData = (
  searchResult: SearchResultData[],
  locations: LocationData[],
  events: EventResponse[]
): SearchResultsProps[] => {
  const performerResults = searchResult.filter(
    (result) =>
      result._type === 'Performer' && result?.venue?.name && result.events
  );

  const listOfSections = [] as SearchResultsProps[];

  if (events.length > 0) {
    listOfSections.push({
      sectionTitle: 'Top results',
      sectionItems: events.slice(0, 10),
    });
  }

  if (performerResults.length > 0) {
    listOfSections.push({
      sectionTitle: 'Teams or performer',
      sectionItems: performerResults,
    });
  }

  if (locations.length > 0) {
    listOfSections.push({
      sectionTitle: 'Location',
      sectionItems: locations,
    });
  }

  return listOfSections;
};
